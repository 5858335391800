import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { SharedModule } from '../../shared';
import { CollAppSubmitterAndSupplierSettingsItemLabelPipe } from './pipes';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    SharedModule,
  ],
  declarations: [
    CollAppSubmitterAndSupplierSettingsItemLabelPipe,
  ],
  exports: [
    CommonModule,
    CollAppSubmitterAndSupplierSettingsItemLabelPipe,
  ],
  providers: [
    CollAppSubmitterAndSupplierSettingsItemLabelPipe,
  ],
})
export class ProjectsSharedModule {
}
