/* eslint-disable lines-between-class-members */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  BasicDialogComponent,
  BasicDialogData,
  BasicDialogType,
} from '../shared/dialogs/basic-dialog/basic.dialog.component';

@Injectable({
  providedIn: 'root',
})
export class BasicDialogService {
  private defaultConfirmTitle: string = 'Please confirm';
  private defaultOkTitle: string = 'Success';
  private defaultOkText: string = 'The command was successfully executed.';
  private defaultErrorTitle: string = 'Error';
  private defaultErrorText: string = 'An error has occurred while saving your data. Please try again. If the problem persists, please contact your administrator.';
  private defaultConfirmText: string = 'Do you really want to cancel the current process? All unsaved data will be lost.';
  private confirmCancelWithOnlyOutdatedAttachmentsText: string = 'Information';
  private confirmCancelWithOutdatedAttachmentsText: string = 'Canceling the current process will not restore outdated attachments.';
  private navigationConfirmTitle: string = 'Unsaved changes on navigation';
  private navigationConfirmText: string = 'There are unsaved changes on the current page. Do you want to save the changes before navigation?';

  constructor(
    private dialog: MatDialog,
  ) { }

  hasOpenDialogs(): boolean {
    const { openDialogs } = this.dialog;

    return (openDialogs != null && openDialogs.length > 0);
  }

  showOkDialog(
    title?: string | null,
    text?: string | null,
    callback?: () => void,
  ): MatDialogRef<BasicDialogComponent> {
    const parsedTitle = (title != null) ? title : this.defaultOkTitle;
    const parsedText = (text != null) ? text : this.defaultOkText;

    const dialogRef = this.dialog.open<BasicDialogComponent, BasicDialogData>(BasicDialogComponent, {
      disableClose: true,
      closeOnNavigation: false,
      autoFocus: false,
      panelClass: ['collapp-dialog', 'collapp-dialog--info'],
      data: {
        title: parsedTitle,
        text: parsedText,
        type: BasicDialogType.INFO,
      },
    });

    dialogRef.afterClosed()
      .subscribe(() => {
        if (callback) {
          callback();
        }
      });

    return dialogRef;
  }

  showErrorDialog(
    title?: string | null,
    text?: string | null,
    callback?: () => void,
  ): MatDialogRef<BasicDialogComponent> {
    const parsedTitle = (title != null) ? title : this.defaultErrorTitle;
    const parsedText = (text != null) ? text : this.defaultErrorText;

    const dialogRef = this.dialog.open<BasicDialogComponent, BasicDialogData>(BasicDialogComponent, {
      disableClose: true,
      closeOnNavigation: false,
      autoFocus: false,
      panelClass: ['collapp-dialog', 'collapp-dialog--error'],
      data: {
        title: parsedTitle,
        text: parsedText,
        type: BasicDialogType.ERROR,
      },
    });

    dialogRef.afterClosed()
      .subscribe(() => {
        if (callback) {
          callback();
        }
      });

    return dialogRef;
  }

  /**
   * The confirmation callback will be executed once the dialog was confirmed.
   * If the confirmation callback completed, the dialog will be closed.
   *
   * @param title
   * @param text
   * @param confirmationCallback
   * @param cancellationCallback
   */
  showConfirmDialog(
    title?: string,
    text?: string,
    confirmationCallback?: () => Observable<boolean | void>,
    cancellationCallback?: () => void,
  ): MatDialogRef<BasicDialogComponent> {
    const parsedTitle = (title != null) ? title : this.defaultConfirmTitle;
    const parsedText = (text != null) ? text : this.defaultConfirmText;

    const dialogRef = this.dialog.open<BasicDialogComponent, BasicDialogData>(BasicDialogComponent, {
      disableClose: true,
      closeOnNavigation: false,
      autoFocus: false,
      panelClass: ['collapp-dialog', 'collapp-dialog--prompt'],
      data: {
        title: parsedTitle,
        text: parsedText,
        type: BasicDialogType.PROMPT,
        confirmationCallback,
      },
    });

    dialogRef.afterClosed()
      .subscribe((result: boolean) => {
        // Result will be false, if confirmation was unsuccessful, or dialog was cancelled.
        if (!result && cancellationCallback) {
          cancellationCallback();
        }
      });

    return dialogRef;
  }

  /**
   * Show the navigation-confirmation dialog
   * abort navigation (cancellationCallback)
   * save changes and continue navigation -> ATTENTION: we need to be sure that it's properly saved
   * discard changes and continue navigation
   *
   * @param title
   * @param text
   * @param saveAndContinueCallback
   * @param discardAndContinueCallback
   */
  showNavigationConfirmDialog(
    title?: string,
    text?: string,
    saveAndContinueCallback?: () => Observable<boolean | void>,
    discardAndContinueCallback?: () => Observable<boolean | void>,
  ): MatDialogRef<BasicDialogComponent> {
    const parsedTitle = (title != null) ? title : this.navigationConfirmTitle;
    const parsedText = (text != null) ? text : this.navigationConfirmText;

    const dialogRef = this.dialog.open<BasicDialogComponent, BasicDialogData>(BasicDialogComponent, {
      disableClose: true,
      closeOnNavigation: false,
      autoFocus: false,
      panelClass: ['collapp-dialog', 'collapp-dialog--prompt'],
      data: {
        title: parsedTitle,
        text: parsedText,
        type: BasicDialogType.NAVIGATION_PROMPT,
        saveAndContinueCallback,
        discardAndContinueCallback,
      },
    });

    return dialogRef;
  }

  showOutdatedAttachmentsCancelDialog(
    useConfirmDialog: boolean = false,
    hasNewOutdatedAttachments: boolean = false,
  ): MatDialogRef<BasicDialogComponent> {
    let dialogText: string | undefined;
    let dialogRef;
    if (useConfirmDialog) {
      dialogText = this.defaultConfirmText;

      if (hasNewOutdatedAttachments) {
        dialogText = `${dialogText}<br/><br/>${this.confirmCancelWithOutdatedAttachmentsText}`;
      }

      dialogRef = this.showConfirmDialog(undefined, dialogText);
    } else {
      const dialogTitle = this.confirmCancelWithOnlyOutdatedAttachmentsText;
      dialogText = this.confirmCancelWithOutdatedAttachmentsText;

      dialogRef = this.showOkDialog(dialogTitle, dialogText);
    }

    return dialogRef;
  }
}
