import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AssignWorkPackageDialogResult } from '../../../components/dialogs/assign-work-package/assign-work-package-dialog-result';
import { AssignWorkPackageComponent } from '../../../components/dialogs/assign-work-package/assign-work-package.component';
import { LevelSlimDtoModel } from '../../../api/models/dtos/level-slim.dto.model';
import { ProjectViewResponseModel } from '../../../api/models/responses/project-view.response.model';
import { WorkPackageViewResponseModel } from '../../../api/models/responses/work-package-view.response.model';
import { ScopeChangeDtoModel } from '../../../api/models/dtos/scope-change.dto.model';
import { QuestionDtoModel } from '../../../api/models/dtos/question.dto.model';
import {
  WorkPackageScopeChangeDialogComponent,
  WorkPackageScopeChangeDialogResult,
} from '../../../components/dialogs/work-package-scope-change';
import {
  EditCreateQuestionDialogComponent,
  EditCreateQuestionDialogResult,
} from '../../../components/dialogs/edit-create-question';
import {
  ConcludeQuestionDialogComponent,
  ConcludeQuestionDialogResult,
} from '../../../components/dialogs/conclude-question';
import {
  ReopenQuestionDialogComponent,
  ReopenQuestionDialogResult,
} from '../../../components/dialogs/reopen-question';
import type {
  WorkPackageWizardDialogComponent,
  WorkPackageWizardDialogData,
  WorkPackageWizardDialogResult,
} from '../../../components/dialogs/work-package-wizard';
import { WorkPackageWizardDialogMode } from '../../../components/dialogs/work-package-wizard/dialog-types';
import {
  CreateFeedbackDialogComponent,
  FeedbackCreateDialogResult,
} from '../../../components/dialogs/create-feedback';
import { BasicDialogComponent } from '../../../shared/dialogs/basic-dialog/basic.dialog.component';
import { BasicDialogService } from '../../../services/basic-dialog.service';
import { ProjectService } from '../../../api/services/project.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { ToastyService } from '../../../shared/toasty';
import { LoadingIndicatorService } from '../../../services/loading-indicator.service';

@Injectable({
  providedIn: 'root',
})
export class WorkPackageDialogService implements OnDestroy {
  get workPackageDialogOpen(): boolean {
    return (this._openWorkPackageDialog !== null);
  }

  get workPackageDialogClosed(): boolean {
    return (this._openWorkPackageDialog === null);
  }

  workPackageDialogChange$: Observable<boolean>;

  workPackageDialogOpen$: Observable<
  MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult> | MatDialogRef<BasicDialogComponent>
  >;

  workPackageDialogClose$: Observable<
  MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult> | MatDialogRef<BasicDialogComponent>
  >;

  private _openWorkPackageDialog: MatDialogRef<
  WorkPackageWizardDialogComponent,
  WorkPackageWizardDialogResult
  > | MatDialogRef<BasicDialogComponent> | null = null;

  private workPackageDialogChangeSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private workPackageDialogOpenSubject$: Subject<
  MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult> | MatDialogRef<BasicDialogComponent>
  > = new Subject();

  private workPackageDialogCloseSubject$: Subject<
  MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult> | MatDialogRef<BasicDialogComponent>
  > = new Subject();

  private confirmDialogRef: MatDialogRef<BasicDialogComponent> | null = null;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private dialogService: BasicDialogService,
    private projectService: ProjectService,
    private loadingIndicatorService: LoadingIndicatorService,
    private toastService: ToastyService,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this.workPackageDialogChange$ = this.workPackageDialogChangeSubject$.asObservable();
    this.workPackageDialogOpen$ = this.workPackageDialogOpenSubject$.asObservable();
    this.workPackageDialogClose$ = this.workPackageDialogCloseSubject$.asObservable();
  }

  ngOnDestroy(): void {
    this.workPackageDialogChangeSubject$.complete();
    this.workPackageDialogOpenSubject$.complete();
    this.workPackageDialogCloseSubject$.complete();
  }

  showArchiveProjectsForbiddenDialog(response: string[]): void {
    let names = '';
    response.forEach((name: string) => {
      names += `<li> ${name} </li>`;
    });

    const title = 'Archive forbidden';
    const text = `You are not allowed to archive this project.<br/>
    The following workPackages are still open:
    <ul>${names}</ul>`;

    const dialogRef = this.dialogService.showOkDialog(title, text);
    this.confirmDialogRef = dialogRef;

    dialogRef
      .afterClosed()
      .subscribe(() => {
        this.confirmDialogRef = null;
      });
  }

  openCreateWorkPackageDialog(
    project: ProjectViewResponseModel,
    levels?: LevelSlimDtoModel[],
  ): Promise<MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult>> {
    return this.openWorkPackageWizardDialog({
      mode: WorkPackageWizardDialogMode.Create,
      project,
      levels,
    });
  }

  /**
   * Opten the dialog to create an ad-hoc workpackage
   * @param project
   * @param levels
   * @returns
   */
  openCreateAdHocWorkPackageDialog():
  Promise<MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult>> {
    return this.openWorkPackageWizardDialog({
      mode: WorkPackageWizardDialogMode.Create,
    });
  }

  openDeleteWorkPackageDialog(workPackage: WorkPackageViewResponseModel): MatDialogRef<BasicDialogComponent> {
    if (this.workPackageDialogOpen) {
      throw new Error('Trying to open a new work package wizard dialog while a previous dialog is still open.');
    }

    const { workPackageId, projectId } = workPackage;

    // Deletes the work package if user confirms

    const dialogRef = this.dialogService
      .showConfirmDialog(
        'Delete Work Package',
        `Are you sure you want to delete ${workPackage.title}?`,
        () => this.projectService
          .deleteWorkPackage$(
            projectId,
            workPackageId,
          ),
      );

    dialogRef
      .afterClosed()
      .pipe(
        finalize(() => {
          dialogRef.close();
          this.workPackageDialogChangeSubject$.next(false);
          this.workPackageDialogCloseSubject$.next(dialogRef);
          this._openWorkPackageDialog = null;
        }),
        // takeUntil(this.destroyed$),
      );

    this.workPackageDialogChangeSubject$.next(true);
    this.workPackageDialogOpenSubject$.next(dialogRef);

    return dialogRef;
  }

  openDeleteWorkPackageDialogWithRedirect(
    workPackage: WorkPackageViewResponseModel,
  ): MatDialogRef<BasicDialogComponent> {
    const dialogRef = this.openDeleteWorkPackageDialog(workPackage);

    dialogRef
      .afterClosed()
      .subscribe({
        next: () => {
          this.toastService.success('Work Package was successfully deleted.');
          this.router.navigate(['/dashboard']);
        },
        error: (error: unknown) => {
          this.errorHandlerService.handleError(error as Error, 'An error has occurred while deleting the Work Package. Please try again.');
        }}
      );

    return dialogRef;
  }

  openEditWorkPackageDialog(
    workPackage: WorkPackageViewResponseModel,
  ): Promise<MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult>> {
    const levels = workPackage.level != null ? workPackage.getLevelPath(workPackage.level.levelId) : [];

    return this.projectService.getProjectView$(workPackage?.projectId)
      .toPromise()
      .then((project) => this.openWorkPackageWizardDialog({
        mode: WorkPackageWizardDialogMode.Edit,
        workPackage,
        project,
        levels,
      }));
  }

  /**
   * The project and work package passed are the source of the duplication.
   * In the wizard the new destination of the project will be selected.
   */
  openDuplicateWorkPackageDialog(
    workPackage: WorkPackageViewResponseModel,
  ): Promise<MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult>> {
    const levels = workPackage.level != null ? workPackage.getLevelPath(workPackage.level.levelId) : [];
    const project = workPackage.project != null ? workPackage.project : undefined;

    return this.openWorkPackageWizardDialog({
      mode: WorkPackageWizardDialogMode.Duplicate,
      workPackage,
      project,
      levels,
    });
  }

  openAssignWorkPackageDialog(
    userId: string,
  ): Promise<MatDialogRef<AssignWorkPackageComponent, AssignWorkPackageDialogResult>> {
    const data = { userId };
    this.loadingIndicatorService.addLoadingRequest();

    return import('../../../components/dialogs/assign-work-package')
      .then((m) => {
        const dialogRef = this.dialog.open(
          m.AssignWorkPackageComponent,
          {
            autoFocus: false,
            disableClose: true,
            closeOnNavigation: false,
            panelClass: ['collapp-dialog', 'collapp-dialog--large'],
            data,
          },
        );

        return dialogRef;
      })
      .catch((error) => {
        this.errorHandlerService.handleError(error, 'Error loading module');
        throw error;
      })
      .finally(() => this.loadingIndicatorService.removeLoadingRequest());
  }

  openMoveWorkPackageDialog(
    workPackage: WorkPackageViewResponseModel,
  ): Promise<MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult>> {
    const levels = workPackage.level != null ? workPackage.getLevelPath(workPackage.level.levelId) : [];
    const project = workPackage.project != null ? workPackage.project : undefined;

    return this.openWorkPackageWizardDialog({
      mode: WorkPackageWizardDialogMode.Move,
      workPackage,
      project,
      levels,
    });
  }

  /**
   * The project and work package passed are the source of the duplication.
   * In the wizard the new destination of the project will be selected.
   */
  openCreateFeedbackDialog(
    projectId: number,

    workPackageId: number,
    isSupplierFeedback: boolean,
  ): Promise<MatDialogRef<CreateFeedbackDialogComponent, FeedbackCreateDialogResult>> {
    return this.openFeedbackDialog(projectId, workPackageId, isSupplierFeedback);
  }

  /**
   * Opens a dialog for creating a ScopeChange entry and transitioning the
   * given WorkPackage to the status 'Scope Change'.
   */
  openScopeChangeCreateDialog(
    workPackage: WorkPackageViewResponseModel,
  ): Promise<MatDialogRef<WorkPackageScopeChangeDialogComponent, WorkPackageScopeChangeDialogResult>> {
    return this.openScopeChangeDialog(workPackage);
  }

  /**
   * Opens a dialog for updating a ScopeChange entry.
   */
  openScopeChangeUpdateDialog(
    workPackage: WorkPackageViewResponseModel,
    scopeChange: ScopeChangeDtoModel,
  ): Promise<MatDialogRef<WorkPackageScopeChangeDialogComponent, WorkPackageScopeChangeDialogResult>> {
    return this.openScopeChangeDialog(workPackage, scopeChange);
  }

  /**
   * Opens a dialog for creating a question.
   */
  openCreateQuestionDialog(
    workPackage: WorkPackageViewResponseModel,
  ): Promise<MatDialogRef<EditCreateQuestionDialogComponent, EditCreateQuestionDialogResult>> {
    this.loadingIndicatorService.addLoadingRequest();

    return import('../../../components/dialogs/edit-create-question')
      .then((m) => this.dialog.open(
        m.EditCreateQuestionDialogComponent,
        {
          width: '300px',
          data: {
            workPackage,
          },
          disableClose: true,
          autoFocus: false,
          panelClass: ['collapp-dialog', 'collapp-dialog--large'],
        },
      ))
      .catch((error) => {
        this.errorHandlerService.handleError(error, 'Error loading module');
        throw error;
      })
      .finally(() => this.loadingIndicatorService.removeLoadingRequest());
  }

  /**
   * Opens a dialog for editing a question.
   */
  openEditQuestionDialog(
    workPackage: WorkPackageViewResponseModel,
    question: QuestionDtoModel,
  ): Promise<MatDialogRef<EditCreateQuestionDialogComponent, EditCreateQuestionDialogResult>> {
    this.loadingIndicatorService.addLoadingRequest();

    return import('../../../components/dialogs/edit-create-question')
      .then((m) => this.dialog.open(
        m.EditCreateQuestionDialogComponent,
        {
          width: '300px',
          data: {
            workPackage,
            question,
          },
          disableClose: true,
          autoFocus: false,
          panelClass: ['collapp-dialog', 'collapp-dialog--large'],
        },
      ))
      .catch((error) => {
        this.errorHandlerService.handleError(error, 'Error loading module');
        throw error;
      })
      .finally(() => this.loadingIndicatorService.removeLoadingRequest());
  }

  /**
   * Creates a Question Re-Open dialog.
   */
  openReopenDialog(
    workPackage: WorkPackageViewResponseModel,
    question: QuestionDtoModel,
  ): Promise<MatDialogRef<ReopenQuestionDialogComponent, ReopenQuestionDialogResult>> {
    this.loadingIndicatorService.addLoadingRequest();

    return import('../../../components/dialogs/reopen-question')
      .then((m) => this.dialog.open(
        m.ReopenQuestionDialogComponent,
        {
          width: '300px',
          data: {
            workPackage,
            question,
          },
          disableClose: true,
          autoFocus: false,
          panelClass: ['collapp-dialog', 'collapp-dialog--large'],
        },
      ))
      .catch((error) => {
        this.errorHandlerService.handleError(error, 'Error loading module');
        throw error;
      })
      .finally(() => this.loadingIndicatorService.removeLoadingRequest());
  }

  /**
   * Creates a Work Package Conclusion dialog.
   */
  openConcludeDialog(
    workPackage: WorkPackageViewResponseModel,
    question: QuestionDtoModel,
  ): Promise<MatDialogRef<ConcludeQuestionDialogComponent, ConcludeQuestionDialogResult>> {
    this.loadingIndicatorService.addLoadingRequest();

    return import('../../../components/dialogs/conclude-question')
      .then((m) => this.dialog.open(
        m.ConcludeQuestionDialogComponent,
        {
          width: '300px',
          data: {
            workPackage,
            question,
          },
          disableClose: true,
          autoFocus: false,
          panelClass: ['collapp-dialog', 'collapp-dialog--large'],
        },
      ))
      .catch((error) => {
        this.errorHandlerService.handleError(error, 'Error loading module');
        throw error;
      })
      .finally(() => this.loadingIndicatorService.removeLoadingRequest());
  }

  private openWorkPackageWizardDialog(
    data: WorkPackageWizardDialogData,
  ): Promise<MatDialogRef<WorkPackageWizardDialogComponent, WorkPackageWizardDialogResult>> {
    if (this.workPackageDialogOpen) {
      throw new Error('Trying to open a new work package wizard dialog while a previous dialog is still open.');
    }

    this.loadingIndicatorService.addLoadingRequest();

    return import('../../../components/dialogs/work-package-wizard')
      .then((m) => {
        const dialogRef = this.dialog.open(
          m.WorkPackageWizardDialogComponent,
          {
            autoFocus: false,
            disableClose: true,
            closeOnNavigation: false,
            panelClass: ['collapp-dialog', 'collapp-dialog--large'],
            data,
          },
        );

        dialogRef
          .afterClosed()
          .subscribe(() => {
            this.workPackageDialogChangeSubject$.next(false);
            this.workPackageDialogCloseSubject$.next(dialogRef);
            this._openWorkPackageDialog = null;
          });

        this.workPackageDialogChangeSubject$.next(true);
        this.workPackageDialogOpenSubject$.next(dialogRef);

        this._openWorkPackageDialog = dialogRef;

        return dialogRef;
      })
      .catch((error) => {
        this.errorHandlerService.handleError(error, 'Error loading module');
        throw error;
      })
      .finally(() => this.loadingIndicatorService.removeLoadingRequest());
  }

  /**
   * Opens a dialog for creating a Feedback entry
   */
  private openFeedbackDialog(
    projectId: number,
    workPackageId: number,
    isSupplierFeedback: boolean,
  ): Promise<MatDialogRef<CreateFeedbackDialogComponent, FeedbackCreateDialogResult>> {
    this.loadingIndicatorService.addLoadingRequest();

    return import('../../../components/dialogs/create-feedback')
      .then((m) => this.dialog.open(
        m.CreateFeedbackDialogComponent,
        {
          width: '300px',
          data: {
            workPackageId,
            projectId,
            closeOnError: false,
            isSupplierFeedback,
          },
          disableClose: true,
          closeOnNavigation: false,
          autoFocus: false,
          panelClass: ['collapp-dialog', 'collapp-dialog--large'],
        },
      ))
      .catch((error) => {
        this.errorHandlerService.handleError(error, 'Error loading module');
        throw error;
      })
      .finally(() => this.loadingIndicatorService.removeLoadingRequest());
  }

  /**
   * Opens a dialog for creating or updating a ScopeChange entry, possibly transitioning the
   * given WorkPackage to the status 'Scope Change'.
   */
  private openScopeChangeDialog(
    workPackage: WorkPackageViewResponseModel,
    scopeChange?: ScopeChangeDtoModel,
  ): Promise<MatDialogRef<WorkPackageScopeChangeDialogComponent, WorkPackageScopeChangeDialogResult>> {
    this.loadingIndicatorService.addLoadingRequest();

    return import('../../../components/dialogs/work-package-scope-change')
      .then((m) => this.dialog.open(
        m.WorkPackageScopeChangeDialogComponent,
        {
          width: '300px',
          data: {
            workPackage,
            scopeChange,
            closeOnError: false,
          },
          closeOnNavigation: false,
          disableClose: true,
          autoFocus: false,
          panelClass: ['collapp-dialog', 'collapp-dialog--large'],
        },
      ))
      .catch((error) => {
        this.errorHandlerService.handleError(error, 'Error loading module');
        throw error;
      })
      .finally(() => this.loadingIndicatorService.removeLoadingRequest());
  }
}
