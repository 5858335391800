import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { CustomPreloadingStrategy } from './helpers/custom-preloading-strategy';

import { AccessGuard } from './guards/access.guard';

import { NotFoundComponent } from './components/not-found/not-found.component';
import { LoginComponent } from './components/login/login.component';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { getAdministrationPermissions, getReportingPermissions } from './app.permisions';
import { LogoutComponent } from './components/logout/logout.component';
import { LoginGuard } from './guards/login.guard';
import { RedirectComponent } from './components/redirect.component';
import type { DashboardModule } from './modules/dashboard';
import type { ProjectsModule } from './modules/projects';
import type { AdministrationModule } from './modules/administration';
import type { UserModule } from './modules/user';
import type { QuestionsModule } from './components/questions';
import type { WorkPackagesModule } from './modules/work-packages';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard')
      .then((m) => m.DashboardModule),
    canLoad: [AccessGuard],
    canActivate: [MsalGuard],
    data: {
      preload: true,
    },
  },
  {
    path: 'projects',
    loadChildren: () => import('./modules/projects')
      .then((m) => m.ProjectsModule),
    canLoad: [AccessGuard],
    canActivate: [MsalGuard],
    data: {
      preload: true,
    },
  },
  {
    path: 'projects/:id/work-packages',
    loadChildren: () => import('./modules/projects/work-package/wp.module')
      .then((m) => m.WpModule),
    canLoad: [AccessGuard],
    canActivate: [MsalGuard],
  },
  {
    path: 'work-packages',
    loadChildren: () => import('./modules/work-packages')
      .then((m) => m.WorkPackagesModule),
    canLoad: [AccessGuard],
    canActivate: [MsalGuard],
    data: {
      preload: true,
    },
  },
  {
    path: 'questions',
    loadChildren: () => import('./modules/questions')
      .then((m) => m.QuestionsModule),
    canLoad: [AccessGuard],
    canActivate: [MsalGuard],
    data: {
      preload: true,
    },
  },
  {
    path: 'administration',
    loadChildren: () => import('./modules/administration')
      .then((m) => m.AdministrationModule),
    canLoad: [AccessGuard],
    canActivate: [MsalGuard],
    data: {
      preload: false,
      permissions: getAdministrationPermissions(),
    },
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user')
      .then((m) => m.UserModule),
    canLoad: [AccessGuard],
    canActivate: [MsalGuard],
    data: {
      preload: true,
    },
  },
  {
    path: 'reporting',
    redirectTo: 'reporting/timesheet',
    pathMatch: 'full',
  },
  {
    path: 'reporting/timesheet',
    // eslint-disable-next-line arrow-parens
    loadChildren: () => import('./modules/timesheet/timesheet.module').then(m => m.TimesheetModule),
    canLoad: [AccessGuard],
    canActivate: [MsalGuard],
    data: {
      preload: false,
      permissions: getReportingPermissions(),
    },
  },
  {
    path: 'reporting/unit-planning',
    // eslint-disable-next-line arrow-parens
    loadChildren: () => import('./modules/unit-planning/unit-planning.module').then(m => m.UnitPlanningModule),
    canLoad: [AccessGuard],
    canActivate: [MsalGuard],
    data: {
      preload: false,
      permissions: true,
    },
  },
  {
    path: 'login',
    children: [
      {
        path: '**',
        component: LoginComponent,
        canActivate: [LoginGuard],
      },
    ],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'redirect',
    children: [
      {
        path: '**',
        component: RedirectComponent,
      },
    ],
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
    useHash: false,
    preloadingStrategy: CustomPreloadingStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
},
    ),
  ],
  exports: [RouterModule],
  providers: [CustomPreloadingStrategy],
})
export class AppRoutingModule { }
