import { EnvironmentConfig } from './environmentConfig';

/**
 * This is the environment configuration for the 'integration' context of the project.
 *
 * The following contexts or environments are available:
 *  - development (environment.ts): For local development.
 *  - integration (environment.integration.ts): First deploy after merges. Development built for production.
 *  - integration-local (environment.integration-local.ts): For testing the PWA build in local development.
 *  - staging (environment.staging.ts): Staging environment before production (acceptance tests).
 *  - production (environment.production.ts): Final stage for live application.
 *
 * @type {EnvironmentConfig}
 */
export const environment: EnvironmentConfig = {
  production: true,
  showBanner: true,
  title: 'Develop',
  apiEndpoint: '//api.dev.collapp.hitachienergy.com',
  msalApiEndpointResource: 'https://graph.windows.net',
  msalConfig: {
    apiEndpointId: 'af550fa7-b867-4206-a033-e0f70a7fa815',
    clientId: 'af550fa7-b867-4206-a033-e0f70a7fa815',
    instance: 'https://login.microsoftonline.com/e77d828a-dd9f-4787-bd3c-3b21d7b14ce6/',
    cacheLocation: 'localStorage',
    endpoints: {
      'https://api.dev.collapp.hitachienergy.com': 'https://graph.windows.net',
    },
  },
  msalLoggingEnabled: true,
  azureBlobServiceUrl: 'https://abbcollappeudevg.blob.core.windows.net/?sv=2018-03-28&ss=b&srt=sco&sp=w&se=2222-04-01T17:23:46Z&st=2019-02-25T10:23:46Z&spr=https&sig=IaOc%2FFG%2FT8LLeYo%2Fpli15xE5VHomZ1wU3Y16sdD6MfY%3D',
  azureBlobTemporaryAttachmentsContainer: 'attachments-temp',
  instrumentationKey: 'e67711d5-73b1-4b64-a2f9-2a4bc6245130',
  ngxsDevelopmentModeEnabled: true,
  ngxsReduxDevtoolsEnabled: true,
  ngxsLoggingEnabled: true,
};
