import { ProjectViewResponseModel } from '../../../api/models/responses/project-view.response.model';
import { WorkPackageViewResponseModel } from '../../../api/models/responses/work-package-view.response.model';
import { LevelSlimDtoModel } from '../../../api/models/dtos/level-slim.dto.model';
import { ProjectSlimResponseModel } from '../../../api/models/responses/project-slim.response.model';

/**
 * You can either create, edit or duplicate a work package with the wizard.
 */
export enum WorkPackageWizardDialogMode {
  Create = 'Create',
  Edit = 'Edit',
  Duplicate = 'Duplicate',
  Move = 'Move',
}

/**
 * Accepted parameters for the wizard dialog.
 */
export interface WorkPackageWizardDialogData {
  mode: WorkPackageWizardDialogMode;
  project?: ProjectViewResponseModel | ProjectSlimResponseModel;
  workPackage?: WorkPackageViewResponseModel;
  levels?: LevelSlimDtoModel[];
}

export type WorkPackageWizardDialogResult = null | {
  workPackageId: number;
  projectId: number;
};
