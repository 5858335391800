import { SimpleNavItem } from './models/simple-nav-item.interface';
import { UserDtoModel } from './api/models/dtos/user.dto.model';
import { getAdministrationPermissions, getPowerBIPermissions, getReportingPermissions } from './app.permisions';

export interface PrimaryNavItem extends SimpleNavItem {
  shortname: string;
  icon: string;
  hidden: (user: UserDtoModel) => boolean;
  activeMenuRegex?: string;
}

export const views: PrimaryNavItem[] = [
  {
    shortname: 'DASH',
    icon: 'ic-dashboard',
    name: 'Dashboard',
    adminClass: false,
    link: ['/dashboard'],
    activeMenuRegex: '/dashboard',
    hidden: (): boolean => false,
  },
  {
    shortname: 'PROJ',
    icon: 'ic-projects',
    name: 'Projects',
    adminClass: false,
    link: ['/projects'],
    activeMenuRegex: '^(?!.*work-packages?\\b).*\\bprojects\\b.*$',
    hidden: (): boolean => false,
  },
  {
    shortname: 'WP\'s',
    icon: 'ic-workpackages',
    name: 'Work Packages',
    adminClass: false,
    link: ['/work-packages'],
    activeMenuRegex: 'work-packages',
    hidden: (): boolean => false,
  },
  {
    shortname: 'Q\'s',
    icon: 'ic-questions',
    name: 'Questions',
    adminClass: false,
    link: ['/questions'],
    activeMenuRegex: '^/questions',
    hidden: (): boolean => false,
  },
  {
    shortname: 'SUPP',
    icon: 'ic-support',
    adminClass: false,
    name: 'Support',
    linkCode: 'support',
    activeMenuRegex: '^support',
    hidden: (): boolean => false,
  },
  {
    shortname: 'REPO1',
    icon: 'ic-timesheet',
    name: 'Timesheet',
    adminClass: false,
    link: ['/reporting/timesheet'],
    activeMenuRegex: '/reporting/timesheet',
    hidden: (user): boolean => !user.hasPermissions(getReportingPermissions()),
  },
  {
    shortname: 'REPO2',
    icon: 'ic-unitplanning',
    name: 'Unit Planning',
    adminClass: false,
    link: ['/reporting/unit-planning'],
    activeMenuRegex: '/reporting/unit-planning',
    hidden: (): boolean => false,
  },
  {
    shortname: 'ADM',
    icon: 'ic-administration',
    adminClass: true,
    name: 'Administration',
    link: ['/administration'],
    activeMenuRegex: '/administration',
    hidden: (user): boolean => !user.hasPermissions(getAdministrationPermissions()),
  },
  {
    shortname: 'PBI',
    icon: 'ic-powerbi',
    adminClass: true,
    name: 'PowerBI',
    linkCode: 'powerBI',
    hidden: (user): boolean => !user.hasPermissions(getPowerBIPermissions()),
  },
  {
    shortname: '404',
    icon: 'ic-info',
    name: 'Bad Link',
    adminClass: true,
    link: ['/wronglink'],
    hidden: (): boolean => true,
  },
];
